import React, { FunctionComponent } from "react";
import styled from "styled-components";

type DividerProps = {
  colour: string;
};

const RecommendationDivider: FunctionComponent<DividerProps> = (props) => {
  return <S.Divider {...props} />;
};

const S = () => {};

S.Divider = styled.div<DividerProps>`
  margin: 5px 0 0 0;
  height: 4px;
  border-radius: 2px;
  background: ${(p) => p.colour};
`;

export default RecommendationDivider;
