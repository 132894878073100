import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";

import { Device } from "../../../utils/device";
import colours from "../../../utils/colours";
import { CM } from "@/components/Typography";

interface RxButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  background?: string;
  buttonText?: string;
  textColour?: string;
  icon?: JSX.Element;
  isLoading?: boolean;
  isSecondary?: boolean;
}

const RxButton: React.FC<RxButtonProps> = (props) => {
  return (
    <S.Button
      $isIcon={!!props.icon}
      $isSecondary={props.isSecondary}
      content={props.buttonText || ""}
      {...props}
    >
      <CM>{props.children || props.buttonText || props.icon || null}</CM>
    </S.Button>
  );
};

interface StyleProps {
  $isIcon?: boolean;
  $isSecondary?: boolean;
  background?: string;
  disabled?: boolean;
  textColour?: string;
}

const S = () => {};

S.Button = styled.button<StyleProps>`
  padding: 12px;
  font-size: 1.6rem;
  ${(p) =>
    (p.$isSecondary &&
      `
    color: ${colours.charcoal};
    background-color: ${colours.shellOverlay};
  `) ||
    `
    color: ${p.textColour || colours.lightShell};
    background-color: ${p.background || colours.teal};
  `};
  border: 3px solid transparent;
  border-radius: 30px;
  min-width: 80%;
  transition: all 0.05s linear;

  &:focus-visible {
    outline: 3px solid ${(p) => p.textColour || colours.lightShell};
    filter: brightness(1.2);
    transform: scale(1.02);
  }

  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
    transform: scale(1.02);
  }

  &:active {
    transform: scale(1);
  }

  ${(p) =>
    p.$isIcon &&
    `
    padding: 10px;
    line-height: 0;
    min-width: 0;
  `}

  ${(p) =>
    p.disabled &&
    `
    pointer-events: none;
    opacity: 70%;
  `}

  @media(min-width: ${Device.mobile}) {
    padding: 12px 24px;
  }
`;

export default RxButton;
