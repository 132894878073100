import React from "react";
import styled from "styled-components";

import { Device } from "@/utils/device";
import { useRouter } from "next/router";
import { ParsedUrlQueryInput } from "querystring";
import colours from "../../utils/colours";

type StyleProps = {
  animationColour?: string;
  colour?: string;
  textColour?: string;
};

type MarketingLinkProps = {
  animationColour?: string;
  children?: string;
  colour?: string;
  download?: boolean;
  href: string;
  linkTarget?: string;
  linkText?: string;
  textColour?: string;
  target?: React.HTMLAttributeAnchorTarget;
  queryParams?: string | ParsedUrlQueryInput;
};

const MarketingLink: React.FC<MarketingLinkProps> = (props) => {
  const router = useRouter();

  const handleClick = (e) => {
    e.preventDefault();
    router.push({
      pathname: props.href,
      query: props.queryParams,
    });
  };

  return (
    <S.ContentContainer
      colour={props.colour}
      animationColour={props.animationColour}
      tabIndex={0}
      textColour={props.textColour}
    >
      <S.A
        target={props.target || props.linkTarget || "_self"}
        download={props.download}
        {...props}
        href={props.href}
        onClick={props.queryParams ? handleClick : null}
      >
        {props.linkText || props.children}
        <S.Arrow colour={props.colour} />
      </S.A>
    </S.ContentContainer>
  );
};

const S = () => {};

S.ContentContainer = styled.span<StyleProps>`
  height: 20px;
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  gap: 50px;
  cursor: pointer;
  color: ${(p) => p.textColour || colours.charcoal};

  &::before {
    background: ${(p) => p.colour || colours.charcoal};
    bottom: -5px;
    content: "";
    display: inline-block;
    height: 2px;
    left: 0;
    position: absolute;
    transition: all 0.3s ease-out;
    width: 100%;

    @media (min-width: ${Device.mobile}) {
      bottom: -10px;
    }
  }

  &::after {
    background: ${(p) => p.animationColour || colours.shellOverlay};
    bottom: -5px;
    content: "";
    display: inline-block;
    height: 2px;
    left: 0;
    position: absolute;
    transition: all 0.3s ease-out;
    width: 0;

    @media (min-width: ${Device.mobile}) {
      bottom: -10px;
    }
  }

  &:hover::after {
    width: 100%;
  }
`;

S.A = styled.a<StyleProps>`
  text-decoration: none;
  color: inherit;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 700;
  color: ${(p) => p.textColour || colours.charcoal};

  @media (min-width: ${Device.mobile}) {
    font-size: inherit;
  }
`;

S.Arrow = styled.span<StyleProps>`
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 9px solid ${(p) => p.colour || colours.charcoal};
  margin-left: 15px;
`;

export default MarketingLink;
