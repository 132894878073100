import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Image from "next/image";

import { Device } from "../../utils/devices";
import { CL } from "../Typography";
import colours from "../../utils/colours";
import RxButton from "../ui/inputs/RxButton";
import WooboxMembershipCard from "./WooboxMembershipCard";

type StyleProps = {
  type?: "membership" | "persona";
};

interface WooboxQuizContentProps {
  type: "membership" | "persona";
}

const WooboxQuizContent: FunctionComponent<WooboxQuizContentProps> = (
  props
) => {
  const type = props.type;
  const content = {
    membership: {
      copy: [
        <CL key="copy">
          Are you looking for meaningful connections, exclusive coaching, the
          opportunity to attend over 300 events per year around the best and
          brightest women in business, or all of the above?
        </CL>,
        <CL key="find-out">
          <b>Find out with our handy quiz.</b>
        </CL>,
      ],
      images: [
        <WooboxMembershipCard type="townhouse" key="townhouse" />,
        <WooboxMembershipCard type="accelerate" key="accelerate" />,
        <WooboxMembershipCard type="elevate" key="elevate" />,
        <WooboxMembershipCard type="board" key="board" />,
      ],
      title: (
        <S.Title>
          Which{" "}
          <S.RoxboroughFontWrap>
            membership is right for you?{" "}
          </S.RoxboroughFontWrap>
        </S.Title>
      ),
    },
    persona: {
      copy: [
        <CL key="copy">
          Perhaps you’re looking for the next step in your career but don’t know
          where to start. Or you might be at the top of your game, looking to
          elevate your success even higher – there’s no stopping you!
        </CL>,
        <CL key="find-out">
          <b>Find out with our handy quiz.</b>
        </CL>,
      ],
      images: [
        <S.ImageContainer key="image-yellow">
          <Image
            src="/images/marketing/woman-yellow-portrait.png"
            layout="fill"
            objectFit="cover"
            alt=""
          />
        </S.ImageContainer>,
        <S.ImageContainer key="image-green">
          <Image
            src="/images/marketing/woman-green-portrait.png"
            layout="fill"
            objectFit="cover"
            alt=""
          />
        </S.ImageContainer>,
        <S.ImageContainer key="image-blue">
          <Image
            src="/images/marketing/woman-blue-portrait.png"
            layout="fill"
            objectFit="cover"
            alt=""
          />
        </S.ImageContainer>,
        <S.ImageContainer key="image-orange">
          <Image
            src="/images/marketing/woman-orange-portrait.png"
            layout="fill"
            objectFit="cover"
            alt=""
          />
        </S.ImageContainer>,
      ],
      title: (
        <S.Title>
          What's{" "}
          <S.RoxboroughFontWrap>your work persona? </S.RoxboroughFontWrap>
        </S.Title>
      ),
    },
  };

  if (!props.type) return null;

  return (
    <S.Section {...props} data-cy="woobox-content">
      {content[type].title}
      <S.CopyContainer type={type}>{content[type].copy}</S.CopyContainer>
      <S.ImagesContainer type={type}>{content[type].images}</S.ImagesContainer>
      <S.ButtonContainer type={type} href="/quiz/personality" target="_blank">
        <RxButton buttonText="Start quiz" />
      </S.ButtonContainer>
    </S.Section>
  );
};

const S = () => {};

S.Section = styled.section<StyleProps>`
  width: 100%;
  padding: 10% 10%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  background: linear-gradient(
    45deg,
    ${colours.shellGuideline},
    ${colours.shell}
  );

  @media (min-width: ${Device.mobile}) {
    padding: 5% 10%;
  }

  @media (min-width: ${Device.tablet}) {
    ${(p) =>
      p.type === "membership" &&
      `
      display: grid;
      grid-template-columns: 1fr;
      text-align: center;
      grid-template-areas:
      'title'
      'images'
      'copy'
      'button';
    `}
  }

  @media (min-width: ${Device.desktop}) {
    ${(p) =>
      p.type === "persona" &&
      `
      display: grid;
      gap: 2rem;
      grid-template-columns: 1fr 2fr;
      grid-template-rows: repeat(4, 1fr);
      grid-template-areas:
      'title images'
      'copy images'
      'copy images'
      'button images';
    `}
  }
`;

S.Title = styled.h3<StyleProps>`
  grid-area: title;
  font-weight: 700;
  font-size: 3rem;
  line-height: 2.6rem;

  @media (min-width: ${Device.mobile}) {
    font-size: 5rem;
    line-height: 4.5rem;
  }
`;

S.RoxboroughFontWrap = styled.span`
  font-family:
    Roxborough CF,
    sans-serif;
  font-weight: 100;
`;

S.CopyContainer = styled.div<StyleProps>`
  grid-area: copy;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media (min-width: ${Device.desktop}) {
    ${(p) =>
      p.type === "membership" &&
      `
      padding: 0 10%;  
    `}

    ${(p) =>
      p.type === "persona" &&
      `
      padding: 0 10% 0 0;  
    `}
  }
`;

S.ImagesContainer = styled.div<StyleProps>`
  grid-area: images;
  height: auto;
  width: 100%;
  display: grid;
  gap: 1rem;

  ${(p) =>
    p.type === "membership" &&
    `
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);

    @media(min-width: ${Device.tablet}) {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;  
    }
  `}

  ${(p) =>
    p.type === "persona" &&
    `
    display: flex;
  `}
`;

S.ImageContainer = styled.div`
  height: auto;
  width: 100%;
  position: relative;
  background: grey;
  aspect-ratio: 1/2;

  @supports not (aspect-ratio: 1/2) {
    &::before {
      float: left;
      padding-top: 200%;
      content: "";
    }

    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }

  @media (min-width: ${Device.desktop}) {
    aspect-ratio: 1/2.5;

    @supports not (aspect-ratio: 1/2.5) {
      &::before {
        float: left;
        padding-top: 250%;
        content: "";
      }

      &::after {
        display: block;
        content: "";
        clear: both;
      }
    }
  }
`;

S.ButtonContainer = styled.a<StyleProps>`
  grid-area: button;
  align-self: center;
  width: 300px;
  display: flex;
  justify-content: center;
  text-decoration: none;

  @media (min-width: ${Device.tablet}) {
    ${(p) =>
      p.type === "membership" &&
      `
      margin: auto;
    `}
  }

  @media (min-width: ${Device.desktop}) {
    ${(p) =>
      p.type === "persona" &&
      `
      justify-content: start;
    `}
  }
`;

export default WooboxQuizContent;
