import React, { FunctionComponent } from "react";
import styled from "styled-components";

interface RxPageLayout {
  children: React.ReactNode;
}

const RxPageLayout: FunctionComponent<RxPageLayout> = ({ children }) => {
  return (
    <S.Container data-cy="marketing-layout">
      <S.ContentWrap>{children}</S.ContentWrap>
    </S.Container>
  );
};

const S = () => {};

S.Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

S.ContentWrap = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
`;

export default RxPageLayout;
